import { render, staticRenderFns } from "./index.vue?vue&type=template&id=227b5446&scoped=true"
import script from "./menusScheduleForm.js?vue&type=script&lang=js&external"
export * from "./menusScheduleForm.js?vue&type=script&lang=js&external"
import style0 from "./index.vue?vue&type=style&index=0&id=227b5446&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "227b5446",
  null
  
)

export default component.exports