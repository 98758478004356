// Constantes
import { MENUS_TYPES } from '@/constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import CategoryFormByTabs from '@/components/elements/categories/CategoryFormByTabs'
import DraggableList from '@/components/ui/DraggableList'
import CategoriesListItem from '@/components/elements/categories/CategoriesListItem'
import SectionInformationBox from '@/components/ui/SectionInformationBox'
import VuetifyPopoverMenu from '@/components/ui/VuetifyPopoverMenu'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Addons
import { ACTION_TYPE } from '@/addons/advancedManagement/constants'
import AdvancedManagementCloneForm from '@/addons/advancedManagement/components/elements/AdvancedManagementCloneForm'
import AddonsNeedUpgradeContent from '@/components/elements/addons/AddonsNeedUpgradeContent'
// Mixins
import uiMixin from '@/mixins/uiMixin'
import addonsMixin from '@/mixins/addonsMixin'
// Vuex
import { mapGetters, mapState } from 'vuex'
// Services
import {
  getCategoryById,
  getEveryMenusByPlaceId,
  getCategoriesByParentId,
  updateCategoryById
} from '@/services/category'
// Utils
import { getPathImage } from '@/utils'
import { isNil, get } from 'lodash'

export default {
  name: 'CategoriesList',
  components: {
    CardContainer,
    CategoriesListItem,
    DraggableList,
    SectionInformationBox,
    VuetifyContentLoading,
    VuetifyPopoverMenu,
    VuetifyToolBar
  },
  mixins: [uiMixin, addonsMixin],
  props: {
    hideBackButton: {
      type: Boolean,
      default: false
    },
    // Carta o menú padre
    parentId: {
      type: String,
      default: null
    },
    // Type de menús a mostrar (solo aplicable a categorías padres)
    type: {
      type: String,
      default: MENUS_TYPES.place.value
    }
  },
  data() {
    return {
      currentParentId: null,
      items: [],
      parentCategory: null,
      processingRequest: true
    }
  },
  computed: {
    ...mapState('app', ['extraSmallDevice']),
    ...mapGetters('place', ['placeData', 'areThereAdditionalLanguages']),
    ...mapGetters('brand', ['brandDataConfig']),
    /**
     * Ocultar o mostrar botón "back" en navegación
     *
     * @return {Boolean}
     */
    hideBackButtonToolBar() {
      return isNil(this.currentParentId) || this.hideBackButton
    },
    /**
     * Items del listado de acciones del "popoverMenu"
     */
    popoverMenuItems() {
      const items = []

      // Acción de creación
      if (this.showActionButtonToolBar) {
        items.push({
          itemFn: this.handleCategoryFormDialog,
          label: 'Categoría nueva',
          params: [
            {
              'data-cy': 'bar-add-action-button'
            }
          ]
        })
      }
      // Acción de clonación
      items.push({
        itemFn: this.handleCloneCategorie,
        label: 'Clonar categoría'
      })

      return items
    },
    /**
     * Ocultar o mostrar botón "acción" en navegación
     *
     * @return {Boolean}
     */
    showActionButtonToolBar() {
      return (
        (isNil(this.currentParentId) && get(this.brandDataConfig, 'allowCreateMenu', true)) ||
        (!isNil(this.currentParentId) && get(this.parentCategory, 'allowCreateCategories', true))
      )
    },
    /**
     * Get the options to show the screen when there are not items
     */
    sectionInformationOptions() {
      return {
        media: isNil(this.currentParentId)
          ? getPathImage('onboarding/sections.svg')
          : getPathImage('onboarding/categories.svg'),
        title: isNil(this.currentParentId) ? 'Cartas / Menús' : 'Categorías cartas / menús',
        description: isNil(this.currentParentId)
          ? 'Aún no has creado ninguna carta / menú'
          : 'Aún no has creado ninguna categoría',
        buttonLabel: isNil(this.currentParentId)
          ? 'Crear carta / menú ¡ahora!'
          : 'Crear categoría ¡ahora!',
        showButton: this.showActionButtonToolBar
      }
    },
    /**
     * Get the current title of current view
     *
     * @return {string} - Dialog title
     */
    titleView() {
      return this.parentCategory ? `${this.parentCategory.name} / Categorías` : 'Cartas / Menús'
    },
    /**
     * Get the current title of current view
     *
     * @return {string} - Dialog title
     */
    titleDialog() {
      return isNil(this.currentParentId) ? ' carta o menú' : ' categoría'
    },
    /**
     * La "toolBar" es sticky
     *
     * @return {Boolean}
     */
    toolBarIsSticky() {
      return this.extraSmallDevice
    }
  },
  watch: {
    /**
     * Watch the router because we use the same component
     * to differents routes and we need that it can reload itself
     */
    async $route(to) {
      this.currentParentId = to.params.id
    },
    /**
     * Cuando el "id" cambia es que hemos cambiado de "categoria",
     * por tanto, realizamos una nueva petición al servidor
     */
    async currentParentId() {
      await this.getEveryNeededData()
    }
  },
  async mounted() {
    // Capturamos evento
    this.$root.$on('reload-list', this.getEveryNeededData)
    // Obtenemos los datos iniciales
    await this.getEveryNeededData()
  },
  destroyed() {
    // Paramos escucha
    this.$root.$off('reload-list')
  },
  methods: {
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * handle change order in items
     *
     * @param {Array} items - items ordered
     */
    async handleChangeOrder(items) {
      if (Array.isArray(items)) {
        try {
          await Promise.all(
            items.map(async (item, index) => {
              // Datos de la posible edición
              const owner = get(item, 'owner', null)
              const editabled = get(item, 'editabled', false)
              const fieldsCustomItem = get(item, 'fieldsCustomItem', [])
              // Es editable el campo "order"?
              const orderIsEditable = Boolean(
                isNil(owner) ||
                  (!isNil(owner) && editabled && fieldsCustomItem.indexOf('order') > -1)
              )

              // Actulizamos posición
              if (orderIsEditable) {
                await updateCategoryById({
                  id: item.id,
                  order: index
                })
              }
            })
          )
        } catch (error) {
          this.handleError(error.message)
        }
      }
    },
    /**
     * handle click on Action (item)
     *
     * @param {string} id - id register in DB
     */
    handleClickAction(id) {
      this.routerPushTo({
        name: isNil(this.currentParentId) ? 'categories' : 'dishes',
        params: { id }
      })
    },
    /**
     * handle create or clone categories
     *
     * @param {Number} index - actions index
     */
    async handleClickMenuItem(index) {
      if (
        this.popoverMenuItems[index] &&
        typeof this.popoverMenuItems[index].itemFn === 'function'
      ) {
        this.popoverMenuItems[index].itemFn()
      }
    },
    /**
     * Abre un "dialog" para crear/editar una categoría
     *
     * @param {string} id - UID category en base de datos
     */
    handleCategoryFormDialog(id = null) {
      // Eres una carta o menú del establecimiento?
      const parentCategoryType = this.parentCategory
        ? get(this.parentCategory, 'type', MENUS_TYPES.place.value)
        : MENUS_TYPES.place.value
      // Pestañas adicionales de configuración a mostrar en el formulario
      const tabs = [
        {
          id: 'basic'
        }
      ]

      // Pestaña de traducciones
      if (parentCategoryType === MENUS_TYPES.place.value && this.areThereAdditionalLanguages) {
        tabs.push({
          id: 'translation'
        })
      }

      // Pestaña de horarios
      if (parentCategoryType === MENUS_TYPES.place.value && isNil(this.currentParentId)) {
        tabs.push({
          id: 'schedule'
        })
      }

      this.modifyAppDialog({
        title: isNil(id) ? `Crear ${this.titleDialog}` : `Editar ${this.titleDialog}`,
        contentComponent: CategoryFormByTabs,
        contentComponentProps: {
          ...(isNil(id) ? {} : { id }),
          parentId: this.currentParentId,
          tabs
        },
        hideActionButtons: true,
        visible: true
      })
    },
    /**
     * Abre un "dialog" para clonar una categoría
     * AdvancedManagement (addon)
     */
    handleCloneCategorie() {
      // Mostramos la modal de clonación
      // o al mensaje de "necesitas actualizar"
      if (this.hasAdvancedManagementAddon) {
        this.modifyAppDialog({
          title: 'Clonar categoría',
          contentComponent: AdvancedManagementCloneForm,
          contentComponentProps: {
            targetCategory: this.currentParentId,
            type: ACTION_TYPE.category
          },
          hideActionButtons: true,
          visible: true
        })
      } else {
        this.modifyAppDialog({
          title: 'Clonar categoría',
          contentComponent: AddonsNeedUpgradeContent,
          contentComponentProps: {
            id: 'advancedManagement'
          },
          optionsActionsButtons: {
            acceptButtonHide: true,
            cancelButtonText: 'Cerrar'
          },

          visible: true
        })
      }
    },
    /**
     * Nos envía a la vista de impresión
     */
    handleClickPrintMenu() {
      // Dirigimos a la sección de impresión
      // o al mensaje de "necesitas actualizar"
      if (this.hasPrintMenuAddon) {
        this.routerPushTo({
          name: 'printMenuPreview',
          params: {
            id: this.currentParentId
          }
        })
      } else {
        this.routerPushTo({
          name: 'addonsNeedUpgrade',
          params: {
            id: 'pro'
          }
        })
      }
    },
    /**
     * Obtenemos la categoría padre inicial
     *
     * @return {string} - uid category o null
     */
    getCurrentParentId() {
      return this.parentId || get(this.$route, 'params.id', null)
    },
    /**
     * Obtenemos todos los datos necesarios para
     * inicializar el listado de categorías
     */
    async getEveryNeededData() {
      this.processingRequest = true
      // Clean the items
      this.items = []

      try {
        // Obtenemos el "parentId" de las categorías
        this.currentParentId = this.getCurrentParentId()
        // Obtenemos hijos de dicha categoría
        if (!isNil(this.currentParentId)) {
          // Obtenemos datos de la categoría padre
          this.parentCategory = await this.getParentCategory(this.currentParentId)
          this.items = await this.getChildCategories(this.currentParentId)
        } else {
          this.items = await this.getParentCategories()
        }
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Obtenemos las cartas o menús (categorías padres)
     */
    async getParentCategories() {
      const items = await getEveryMenusByPlaceId(this.placeData.id)

      // Tras la entrada en vigor del "addon" "takeAway",
      // ahora las cartas (categorías padres) poseen un tipo
      return items.filter((item) => {
        return (
          (isNil(item.type) && this.type === MENUS_TYPES.place.value) || item.type === this.type
        )
      })
    },
    /**
     * Obtenemos las categorías de las cartas (categorías hijas)
     *
     * @param {string} id - parent categorie
     * @return {Array}
     */
    async getChildCategories(id) {
      const items = await getCategoriesByParentId(id)
      return items
    },
    /**
     * Get data current parent category
     *
     * @param {string} id - UID category
     * @return {object | null}
     */
    async getParentCategory(id) {
      const parentCategory = id ? await getCategoryById(id) : null
      return parentCategory
    }
  }
}
