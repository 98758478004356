// Components
import AddonsNeedUpgradeContent from '@/components/elements/addons/AddonsNeedUpgradeContent'
import CategoryForm from '../CategoryForm'
import MenusScheduleForm from '@/addons/menusSchedule/views/MenusScheduleForm'
import TranslateForm from '@/components/elements/translate/TranslateForm'
import VuetifyTabs from '@/components/ui/VuetifyTabs'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
// Utils
import { get, isNil } from 'lodash'

export default {
  name: 'CategoryFormByTabs',
  components: {
    VuetifyTabs
  },
  mixins: [addonsMixin],
  props: {
    // Id de la categoría (edición)
    id: {
      type: String,
      default: null
    },
    // Id de la categoría padre, si existiera
    parentId: {
      type: String,
      default: null
    },
    // Pestañas "adicionales" de configuración
    tabs: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      items: []
    }
  },
  mounted() {
    this.getEveryNeededData()
  },
  methods: {
    /**
     * Establecemos/obtenemos todos los datos
     * necesarios para inicializar el componente
     */
    getEveryNeededData() {
      // Pestañas disponibles
      const basicTab = this.tabs.find((tab) => tab.id === 'basic')
      const translationTab = this.tabs.find((tab) => tab.id === 'translation')
      const scheduleTab = this.tabs.find((tab) => tab.id === 'schedule')

      // Pestaña datos básicos
      if (!isNil(basicTab)) {
        this.items.push({
          label: 'Básico',
          component: CategoryForm,
          componentProps: {
            id: this.id,
            parentId: this.parentId,
            ...get(basicTab, 'options', {})
          }
        })
      }

      // Pestaña multi-idiomas
      if (!isNil(translationTab)) {
        this.items.push({
          label: 'Traducciones',
          component: TranslateForm,
          componentProps: { model: 'category', ...get(translationTab, 'options', {}) }
        })
      }

      // Pestaña horarios
      if (!isNil(scheduleTab)) {
        this.items.push({
          label: 'Horarios',
          component: this.hasMenusScheduleAddon ? MenusScheduleForm : AddonsNeedUpgradeContent,
          componentProps: this.hasMenusScheduleAddon
            ? { id: this.id, ...get(scheduleTab, 'options', {}) }
            : {
                id: 'menuSchedule'
              }
        })
      }
    }
  }
}
