// Components
import FormButtons from '@/components/ui/FormButtons'
import VuetifySchedules from '@/components/ui/VuetifySchedules'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
// Services
import { updateCategoryById } from '@/services/category'
// Utils
import { get, isNil } from 'lodash'

export default {
  name: 'MenusScheduleForm',
  components: {
    FormButtons,
    VuetifySchedules,
    VuetifyContentLoading
  },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    id: {
      type: String,
      default: null
    },
    // Variables del componente padre "VuetifyTabs"
    index: {
      // Indice que ocupo dentro del componente
      type: Number,
      default: 0
    },
    itemsData: {
      // Datos que se comparten entre pestañas del componente
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      // Form
      formFields: {
        schedule: [],
        enabledSchedule: false
      },
      formFieldsValidations: {},
      // Other
      categoryData: null,
      processingRequest: false
    }
  },
  computed: {
    /**
     * Campos que son editables en el formulario
     *
     * @return {array | boolean} - listado de los campos editables
     */
    editableFields() {
      const owner = get(this.categoryData, 'owner', null)
      const editabled = get(this.categoryData, 'editabled', false)
      const fieldsCustomItem = get(this.categoryData, 'fieldsCustomItem', [])

      if (!isNil(owner)) {
        return editabled ? fieldsCustomItem : []
      }

      return true // todo es editable
    },
    /**
     * El formulario es editable?
     *
     * @return {boolean}
     */
    formIsEditable() {
      return (
        (Array.isArray(this.editableFields) && this.editableFields.length > 0) ||
        this.editableFields === true
      )
    },
    /**
     * La categoría fue creada por una marca?
     *
     * @return {boolean}
     */
    isBrandCategory() {
      return !isNil(get(this.categoryData, 'owner', null))
    }
  },
  watch: {
    'formFields.enabledSchedule'(value) {
      if (value === false) {
        this.formFields.schedule = []
      }
    }
  },
  mounted() {
    this.getEveryNeededData()
  },
  methods: {
    /**
     * When the user must click on cancel button
     */
    handleCancelButton() {
      this.modifyAppDialog({
        visible: false
      })
    },
    /**
     * Evento lanzado tras la modificación del horario
     *
     * @param {Array} value - horarios
     */
    handleSchedule(value) {
      this.formFields.schedule = value
    },
    /**
     * Cargamos todos los datos necesarios para el formulario
     */
    getEveryNeededData() {
      // Obtenemos datos de la primera pestaña
      this.categoryData = get(this.itemsData, '0.data', {})
      // Establecemos datos del formulario
      this.setFormFieldsValues()
    },

    /**
     * Dado un campo del formulario, comprobamos si este puede
     * ser editado
     *
     * @param {string} name - nombre del campo
     * @return {boolean} - es editable?
     */
    isEditable(name = null) {
      return Array.isArray(this.editableFields)
        ? this.editableFields.indexOf(name) > -1
        : this.editableFields
    },
    /**
     * Establecemos los datos del menú que estamos tratando
     * y los valores iniciales del formulario
     */
    async setFormFieldsValues() {
      const { enabledSchedule = false, schedule = [] } = this.categoryData
      this.formFields.schedule = schedule
      this.formFields.enabledSchedule = enabledSchedule
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Datos a salvar
      const dataToSave = !Array.isArray(this.editableFields)
        ? this.formFields
        : this.editableFields.reduce((accFields, field) => {
            if (!isNil(this.formFields[field])) {
              accFields[field] = this.formFields[field]
            }
            return accFields
          }, {})
      // Actualización
      await updateCategoryById({
        id: this.id,
        ...dataToSave
      })

      // Modificamos "itemsData" del componente padre (VuetifyTabs)
      this.changeItemsData(
        {
          id: this.id,
          data: dataToSave,
          model: 'category'
        },
        0
      )
      // show info
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    },
    /**
     * Modificamos el atributo "itemsData" del componente padre
     *
     * @param {Object} data - datos a emitir
     * @param {Number} index - indice de la pestaña donde almacenar los datos
     */
    changeItemsData(data, index = this.index) {
      this.$parent.$parent.$parent.$emit('onChangeItemsData', index, data)
    }
  },
  validations: {
    formFields: {
      enabledSchedule: {},
      schedule: {}
    }
  }
}
