<template>
  <div class="categories-list-item">
    <span class="content" @click="handleClickName(item.id)">
      <!-- name -->
      <span class="name">
        {{ item.name }}
        <template v-if="!item.parentId && item.price">
          &bull;
          <CurrencyPosition :price="item.price" />
        </template>
        <span v-if="item.disabled" class="error--text">(N/D)</span>
      </span>
      <!-- description -->
      <span v-if="item.description" class="description">
        {{ item.description }}
      </span>
    </span>
    <span class="action" @click="handleClickAction(item.id)">
      <v-btn icon>
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </span>
  </div>
</template>

<script>
// Components
import CurrencyPosition from '@/components/ui/CurrencyPosition'

export default {
  name: 'CategoriesListItem',
  components: { CurrencyPosition },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    /**
     * Handle click on name
     *
     * @param {string} id - item id
     */
    handleClickName(id) {
      this.$emit('onClickName', id)
    },
    /**
     * Handle click on action
     *
     * @param {string} id - item id
     */
    handleClickAction(id) {
      this.$emit('onClickAction', id)
    }
  }
}
</script>

<style lang="scss" scoped>
.categories-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .name {
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.15px;
  }
  .description {
    color: $theme_grey;
    font-size: 0.75rem;
    line-height: 1.25rem;
    letter-spacing: 0.25px;
  }
}
</style>
